import React from 'react'

// Images
import logo from '../images/logo-bn.png'

const Footer = () => {
  return (
    <footer className='grisoscuro2 ajustalalto-pie'>
      <div className='contenedor'>
        <div className='fila'>
          <div className='col12'>
            <div className='separa1'></div>
            <img className='centrado' src={logo} alt='My Domain Place' />
            <div className='separa'></div>
          </div>
        </div>
      </div>
      <div className='separa-min'></div>
      <div className='centrado'>
        <p className='zona-legal grisoscuro2'>
          ASG Digital Management LLC |
          ASG Digital LTD
        </p>
      </div>
    </footer>
  )
}

export default Footer
