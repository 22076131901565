import React, { useRef, useState } from 'react'

// Components
import Navbar from './Navbar'
import Search from './Search'
import Contact from './Contact'
import Footer from './Footer'
import Domains from './Domains'
import Categories from './Categories'
import Category from './Category'
import BestDomains from './BestDomains'
import LastDomains from './LastDomains'

const scrollToRef = ref => window.scrollTo(0, ref.current.offsetTop - 150)

const Root = props => {
  const {
    domains,
    category,
    setCategory,
    showCategories,
    setShowCategories,
    showBestDomains,
    setShowBestDomains,
    showLastDomains,
    setShowLastDomains,
    isSpanish,
    setIsSpanish
  } = props
  const categories = [...new Set(domains.map(doc => doc.category))]
  const bestDomains = domains.filter(doc => doc.isBest)
  const [searchParameter, setSearchParameter] = useState(null)

  const domainsRef = useRef(null)
  const categoryRef = useRef(null)
  const lastDomainsRef = useRef(null)
  const bestDomainsRef = useRef(null)
  const contactRef = useRef(null)
  const searchRef = useRef(null)
  const singleCategoryRef = useRef(null)
  const executeScroll = ref => scrollToRef(ref)

  return (
    <div>
      <div className='page-wrapper ajustalalto-contenido'>
        <Navbar
          setCategory={setCategory}
          setShowLastDomains={setShowLastDomains}
          setShowCategories={setShowCategories}
          setShowBestDomains={setShowBestDomains}
          executeScroll={executeScroll}
          lastDomainsRef={lastDomainsRef}
          categoryRef={categoryRef}
          bestDomainsRef={bestDomainsRef}
          contactRef={contactRef}
          domainsRef={domainsRef}
          isSpanish={isSpanish}
          setIsSpanish={setIsSpanish}
          searchRef={searchRef}
          setSearchParameter={setSearchParameter}
        />

        <div className='separa-menu'></div>

        <div className='contenidoweb'>
          <div className='fon-cabecera centradovertical'>
            <div className='box-slogan'>
              <span className='slg-1 fuente-destacada c-blanco'>
                LOOK FOR YOUR
              </span>
              <h1 className='slg-2 fuente-destacada c-blanco'>
                BEST DOMAIN NAME HERE
              </h1>
            </div>
          </div>
          <div className='separa1' ref={searchRef}></div>

          {searchParameter ? (
            <Search
              searchParameter={searchParameter}
              domains={domains}
              categories={categories}
              setShowCategories={setShowCategories}
              setCategory={setCategory}
              isSpanish={isSpanish}
              singleCategoryRef={singleCategoryRef}
              executeScroll={executeScroll}
            />
          ) : null}

          {!category &&
            !showBestDomains &&
            !showCategories &&
            !showLastDomains && (
              <Domains
                domains={domains}
                categories={categories}
                bestDomains={bestDomains}
                setCategory={setCategory}
                setShowBestDomains={setShowBestDomains}
                setShowCategories={setShowCategories}
                setShowLastDomains={setShowLastDomains}
                domainsRef={domainsRef}
                isSpanish={isSpanish}
              />
            )}

          {showLastDomains ? (
            <LastDomains
              domains={domains}
              lastDomainsRef={lastDomainsRef}
              isSpanish={isSpanish}
            />
          ) : null}

          {showCategories ? (
            <Categories
              categoryRef={categoryRef}
              categories={categories}
              setCategory={setCategory}
              setShowCategories={setShowCategories}
            />
          ) : null}

          {showBestDomains ? (
            <BestDomains
              bestDomains={bestDomains}
              bestDomainsRef={bestDomainsRef}
              isSpanish={isSpanish}
            />
          ) : null}

          <div className='separa1' ref={singleCategoryRef}></div>
          {category ? (
            <Category
              category={category}
              domains={domains}
              isSpanish={isSpanish}
            />
          ) : null}

          <div className='separa-menu' style={{ height: '60px' }}></div>
          <Contact contactRef={contactRef} isSpanish={isSpanish} />
          <Footer />
        </div>
      </div>
    </div>
  )
}

export default Root
