import React, { useState } from 'react'

const Categories = props => {
  const { categoryRef, categories, setCategory, setShowCategories } = props
  const [limit, setLimit] = useState(20)
  const capitalizeFirstLetter = string => {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }
  return (
    <div className='contenedor'>
      <div className='fila-flex'>
        <div className='col12-flex' id='categories' ref={categoryRef}>
          <div className='box-list2 fuente-destacada'>
            <span>SORT BY CATEGORY</span>
          </div>
          <div className='box-list2frs'>
            <span className='fuente-destacada'>
              Browse popular categories here
            </span>
          </div>
          <div className='box-categories'>
            <div className='fila-flex'>
              {categories
                .sort((a, b) => {
                  if (a < b) {
                    return -1
                  }
                  if (a > b) {
                    return 1
                  }
                  return 0
                })
                .map((category, index) => {
                  const catNameArr = category.split(' ')
                  const newCatNameArr = catNameArr.map(cat =>
                    capitalizeFirstLetter(cat)
                  )
                  const categoryName = newCatNameArr.join(' ')
                  if (index <= limit) {
                    return (
                      <div
                        key={index}
                        className='col06-flex col06-flex-xs col04-flex-xl  col03-flex-xxl'
                      >
                        <button
                          className='box-categories-item elipsis'
                          onClick={() => {
                            setShowCategories(false)
                            setCategory(category)
                          }}
                          style={{
                            border: 'none',
                            outline: 'none',
                            width: '100%',
                            fontSize: '1rem',
                            textAlign: 'left'
                          }}
                        >
                          <span className='cajita sepder'></span>
                          {categoryName}
                        </button>
                      </div>
                    )
                  } else {
                    return null
                  }
                })}
            </div>
          </div>
          <div class='separa1'></div>
          <div class='full center'>
            <button
              className='boton more'
              onClick={e => {
                setLimit(Number(limit) + 21)
              }}
            >
              See More
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Categories
