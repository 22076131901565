import React, { useState } from 'react'

// Formik & Yup
import { Formik } from 'formik'
import * as Yup from 'yup'

// axios
import axios from 'axios'

// SweetAlert
import swal from 'sweetalert'

// Images
import logo from '../images/logo-bn.png'
import arrowIcon from '../images/flecha.svg'

// RECAPTCHA
import ReCAPTCHA from 'react-google-recaptcha'

const EmailSchema = Yup.object().shape({
  name: Yup.string().required('Required *'),
  email: Yup.string().required('Required *'),
  confirmEmail: Yup.string().required('Required *'),
  phoneNumber: Yup.number().required('Required *'),
  country: Yup.string().required('Required *')
})

const Landing = props => {
  const { domainData, isSpanish } = props
  const [error, setError] = useState(null)
  const [captcha, setCaptcha] = useState(null)
  const countryList = [
    'Afghanistan',
    'Albania',
    'Algeria',
    'American Samoa',
    'Andorra',
    'Angola',
    'Anguilla',
    'Antarctica',
    'Antigua and Barbuda',
    'Argentina',
    'Armenia',
    'Aruba',
    'Australia',
    'Austria',
    'Azerbaijan',
    'Bahamas (the)',
    'Bahrain',
    'Bangladesh',
    'Barbados',
    'Belarus',
    'Belgium',
    'Belize',
    'Benin',
    'Bermuda',
    'Bhutan',
    'Bolivia (Plurinational State of)',
    'Bonaire, Sint Eustatius and Saba',
    'Bosnia and Herzegovina',
    'Botswana',
    'Bouvet Island',
    'Brazil',
    'British Indian Ocean Territory (the)',
    'Brunei Darussalam',
    'Bulgaria',
    'Burkina Faso',
    'Burundi',
    'Cabo Verde',
    'Cambodia',
    'Cameroon',
    'Canada',
    'Cayman Islands (the)',
    'Central African Republic (the)',
    'Chad',
    'Chile',
    'China',
    'Christmas Island',
    'Cocos (Keeling) Islands (the)',
    'Colombia',
    'Comoros (the)',
    'Congo (the Democratic Republic of the)',
    'Congo (the)',
    'Cook Islands (the)',
    'Costa Rica',
    'Croatia',
    'Cuba',
    'Curaçao',
    'Cyprus',
    'Czechia',
    "Côte d'Ivoire",
    'Denmark',
    'Djibouti',
    'Dominica',
    'Dominican Republic (the)',
    'Ecuador',
    'Egypt',
    'El Salvador',
    'Equatorial Guinea',
    'Eritrea',
    'Estonia',
    'Eswatini',
    'Ethiopia',
    'Falkland Islands (the) [Malvinas]',
    'Faroe Islands (the)',
    'Fiji',
    'Finland',
    'France',
    'French Guiana',
    'French Polynesia',
    'French Southern Territories (the)',
    'Gabon',
    'Gambia (the)',
    'Georgia',
    'Germany',
    'Ghana',
    'Gibraltar',
    'Greece',
    'Greenland',
    'Grenada',
    'Guadeloupe',
    'Guam',
    'Guatemala',
    'Guernsey',
    'Guinea',
    'Guinea-Bissau',
    'Guyana',
    'Haiti',
    'Heard Island and McDonald Islands',
    'Holy See (the)',
    'Honduras',
    'Hong Kong',
    'Hungary',
    'Iceland',
    'India',
    'Indonesia',
    'Iran (Islamic Republic of)',
    'Iraq',
    'Ireland',
    'Isle of Man',
    'Israel',
    'Italy',
    'Jamaica',
    'Japan',
    'Jersey',
    'Jordan',
    'Kazakhstan',
    'Kenya',
    'Kiribati',
    "Korea (the Democratic People's Republic of)",
    'Korea (the Republic of)',
    'Kuwait',
    'Kyrgyzstan',
    "Lao People's Democratic Republic (the)",
    'Latvia',
    'Lebanon',
    'Lesotho',
    'Liberia',
    'Libya',
    'Liechtenstein',
    'Lithuania',
    'Luxembourg',
    'Macao',
    'Madagascar',
    'Malawi',
    'Malaysia',
    'Maldives',
    'Mali',
    'Malta',
    'Marshall Islands (the)',
    'Martinique',
    'Mauritania',
    'Mauritius',
    'Mayotte',
    'Mexico',
    'Micronesia (Federated States of)',
    'Moldova (the Republic of)',
    'Monaco',
    'Mongolia',
    'Montenegro',
    'Montserrat',
    'Morocco',
    'Mozambique',
    'Myanmar',
    'Namibia',
    'Nauru',
    'Nepal',
    'Netherlands (the)',
    'New Caledonia',
    'New Zealand',
    'Nicaragua',
    'Niger (the)',
    'Nigeria',
    'Niue',
    'Norfolk Island',
    'Northern Mariana Islands (the)',
    'Norway',
    'Oman',
    'Pakistan',
    'Palau',
    'Palestine, State of',
    'Panama',
    'Papua New Guinea',
    'Paraguay',
    'Peru',
    'Philippines (the)',
    'Pitcairn',
    'Poland',
    'Portugal',
    'Puerto Rico',
    'Qatar',
    'Republic of North Macedonia',
    'Romania',
    'Russian Federation (the)',
    'Rwanda',
    'Réunion',
    'Saint Barthélemy',
    'Saint Helena, Ascension and Tristan da Cunha',
    'Saint Kitts and Nevis',
    'Saint Lucia',
    'Saint Martin (French part)',
    'Saint Pierre and Miquelon',
    'Saint Vincent and the Grenadines',
    'Samoa',
    'San Marino',
    'Sao Tome and Principe',
    'Saudi Arabia',
    'Senegal',
    'Serbia',
    'Seychelles',
    'Sierra Leone',
    'Singapore',
    'Sint Maarten (Dutch part)',
    'Slovakia',
    'Slovenia',
    'Solomon Islands',
    'Somalia',
    'South Africa',
    'South Georgia and the South Sandwich Islands',
    'South Sudan',
    'Spain',
    'Sri Lanka',
    'Sudan (the)',
    'Suriname',
    'Svalbard and Jan Mayen',
    'Sweden',
    'Switzerland',
    'Syrian Arab Republic',
    'Taiwan',
    'Tajikistan',
    'Tanzania, United Republic of',
    'Thailand',
    'Timor-Leste',
    'Togo',
    'Tokelau',
    'Tonga',
    'Trinidad and Tobago',
    'Tunisia',
    'Turkey',
    'Turkmenistan',
    'Turks and Caicos Islands (the)',
    'Tuvalu',
    'Uganda',
    'Ukraine',
    'United Arab Emirates (the)',
    'United Kingdom of Great Britain and Northern Ireland (the)',
    'United States Minor Outlying Islands (the)',
    'United States of America (the)',
    'Uruguay',
    'Uzbekistan',
    'Vanuatu',
    'Venezuela (Bolivarian Republic of)',
    'Viet Nam',
    'Virgin Islands (British)',
    'Virgin Islands (U.S.)',
    'Wallis and Futuna',
    'Western Sahara',
    'Yemen',
    'Zambia',
    'Zimbabwe',
    'Åland Islands'
  ]

  const handleSubmit = values => {
    const newMail = {
      mailFrom: 'inquiries@mydomainplace.com',
      mailTo: ['nathan@siouxmedia.com', 'develop@estudiocactus.com'],
      subject: `Domain Inquiry for ${domainData.url}`,
      html: `<div><h1> Name: ${values.name} </h1>
        <p> Email: ${values.email} </p>
        <p> Phone: ${values.phoneNumber} </p>
        <p> Country: ${values.country} </p> 
        <p> Message: ${values.message} </p>
      <div>`
    }

    axios
      .post(
        'https://us-central1-social-cash-out.cloudfunctions.net/sendEmail',
        newMail
      )
      .then(res => console.log('Success!', res))
      .catch(e => console.log(e))
  }
  return (
    <div className='contenidoweb'>
      <div className='fon-landing centradovertical landing'>
        <div className='contenedor'>
          <div className='fila-flex'>
            <div className='col12-flex'>
              <div className='center box-slogan'>
                <div className='separa-min telefono tableta'></div>
                <a href='https://mydomainplace.com/'>
                  <img src={logo} alt='My Domain Place' />
                </a>
                <div className='separa'></div>
                <span className='box-nombredominio fuente-destacada'>
                  {domainData.url}
                </span>
                <div className='separa-min'></div>
                <span className='bajo-dominio fuente-destacada c-blanco'>
                  {isSpanish ? 'está a la venta' : 'is for sale'}
                </span>
                <div className='separa'></div>
              </div>
            </div>
            <div className='col06-flex'>
              <div
                className='centradovertical full coltexto relative'
                style={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                <img
                  className='flecha escritorio'
                  src={arrowIcon}
                  alt='arrow icon'
                />
                <div className='box-boxizq enlinea'>
                  <span className='linea3 fuente-destacada c-blanco'>
                    BUY NOW
                  </span>
                  <span className='linea2 fuente-destacada c-blanco'>
                    {domainData.price}$
                  </span>

                  <div className='separa-min'></div>
                  <p className='linea4 fuente-destacada c-blanco'>
                    Contact us filling the form
                    <br className='escritorio' />
                    One of our agents will contact you
                    <br className='escritorio' />
                    in less than{' '}
                    <span style={{ color: '#feef00' }}>24 working hours</span>
                  </p>
                </div>
              </div>
            </div>
            <div className='col06-flex'>
              <div className='box-formlanding'>
                <Formik
                  initialValues={{
                    name: '',
                    email: '',
                    confirmEmail: '',
                    phoneNumber: '',
                    country: '',
                    message: 'Your comments...'
                  }}
                  validationSchema={EmailSchema}
                  enableReinitialize
                >
                  {({ values, validateForm, setFieldValue, resetForm }) => (
                    <form
                      id='formulario_landing'
                      name='formulario_landing'
                      className=''
                      action='../base/callback.php'
                      method='post'
                      target='self'
                    >
                      <input
                        className='formulario obligatorio full'
                        name='name'
                        type='text'
                        title='Name and Surname'
                        placeholder={
                          isSpanish ? 'Nombre y Apellidos' : 'Name and Surname'
                        }
                        required=''
                        value={values.name}
                        onChange={e => {
                          setError(null)
                          setFieldValue('name', e.target.value)
                        }}
                      />
                      <input
                        className='formulario obligatorio full'
                        name='email'
                        type='text'
                        title='Email'
                        placeholder='Email'
                        required=''
                        value={values.email}
                        onChange={e => {
                          setError(null)
                          setFieldValue('email', e.target.value)
                        }}
                      />
                      <input
                        className='formulario obligatorio full'
                        name='confirmemail'
                        type='text'
                        title='Confirm Email'
                        placeholder={
                          isSpanish ? 'Confirma Email' : 'Confirm Email'
                        }
                        required=''
                        value={values.confirmEmail}
                        onChange={e => {
                          setError(null)
                          setFieldValue('confirmEmail', e.target.value)
                        }}
                      />
                      <input
                        className='formulario obligatorio full'
                        name='telefono'
                        type='text'
                        title={isSpanish ? 'Teléfono' : 'Phone Number'}
                        placeholder={isSpanish ? 'Teléfono' : 'Phone Number'}
                        required=''
                        value={values.phoneNumber}
                        onChange={e => {
                          setError(null)
                          setFieldValue('phoneNumber', e.target.value)
                        }}
                      />
                      <select
                        className='formulario obligatorio full'
                        name=''
                        value={values.country}
                        onChange={e => {
                          setError(null)
                          setFieldValue('country', e.target.value)
                        }}
                      >
                        <option defaultValue>
                          {isSpanish ? 'Selecciona Pais' : 'Select Country'}
                        </option>
                        {countryList.map((country, index) => (
                          <option key={index}> {country}</option>
                        ))}
                      </select>

                      <textarea
                        className='formulario redondo-min notfull'
                        name='mensaje_texto'
                        id='mensaje_texto'
                        rows='6'
                        value={values.message}
                        onChange={e => {
                          setError(null)
                          setFieldValue('message', e.target.value)
                        }}
                      ></textarea>

                      <div style={{ color: 'red', textAlign: 'center' }}>
                        {error}
                      </div>
                      <ReCAPTCHA
                        sitekey='6Lfrk7QZAAAAACWmEey3YueHZ2FWNVOH8DOLxfYG'
                        onChange={e => {
                          setError(null)
                          setCaptcha(true)
                        }}
                      />
                      <div className='center full'>
                        <div className='separa-min'></div>
                        <input
                          name='enviar'
                          className='formulario boton enviarlandig full fuente-destacada'
                          id='enviar'
                          type='button'
                          value={isSpanish ? 'Obtener Precio' : 'Send Request'}
                          onClick={e => {
                            e.preventDefault()
                            validateForm()
                              .then(res => {
                                let errors = []
                                for (const value in res) {
                                  errors.push(value)
                                }
                                if (errors.length > 1 && res.phoneNumber) {
                                  setError(
                                    'Please fill in all the required fields'
                                  )
                                } else if (res.phoneNumber) {
                                  setError(
                                    'Please provide a valid phone number'
                                  )
                                } else if (!captcha) {
                                  setError('Please complete the captcha!')
                                } else if (
                                  values.email !== values.confirmEmail
                                ) {
                                  setError(
                                    'Email fields do not match! Please try again!'
                                  )
                                } else {
                                  handleSubmit(values)
                                  resetForm({})
                                  swal(
                                    'Message Sent!',
                                    'Click ok to continue!',
                                    'success'
                                  )
                                }
                              })
                              .catch(e => console.log(e))
                          }}
                        />
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Landing
