import React, { useState } from 'react'

import logo from '../images/logo-bn.png'
import enFlag from '../images/en.png'
import esFlag from '../images/es.png'

const Navbar = props => {
  const {
    setShowLastDomains,
    setShowCategories,
    setShowBestDomains,
    setCategory,
    executeScroll,
    lastDomainsRef,
    bestDomainsRef,
    categoryRef,
    contactRef,
    domainsRef,
    isSpanish,
    setIsSpanish,
    setSearchParameter,
    searchRef
  } = props

  const [bgCheck, setBgCheck] = useState(null)
  const [mobileOpen, setMobileOpen] = useState(false)

  window.onscroll = () => {
    if (window.pageYOffset > 150) {
      setBgCheck(true)
    } else {
      setBgCheck(false)
    }
  }

  return (
    <header
      className='fontop main_menu home_menu'
      style={{
        background: bgCheck ? '#21A4F7' : 'none',
        transition: 'all 1500ms ease'
      }}
    >
      <div className='contenedor'>
        <div className='fila'>
          <div className='col02'>
            <div className='logotipo izq'>
              <button
                href='/'
                title='My Domain Place'
                style={{
                  background: 'none',
                  border: 'none',
                  color: '#fff',
                  fontWeight: 300,
                  lineHeight: '3em',
                  fontSize: '1rem',
                  cursor: 'pointer',
                  outline: 'none'
                }}
                onClick={() => {
                  setCategory(null)
                  setShowCategories(false)
                  setShowLastDomains(false)
                  setShowBestDomains(false)
                  setTimeout(() => {
                    executeScroll(domainsRef)
                  }, 1)
                }}
              >
                <img src={logo} alt='My Domain Place' />
              </button>
            </div>
          </div>

          <div className='col10'>
            <div className='zona-menu'>
              <ul className='menu-desktop color'>
                <li className='menu-opcion'>
                  <button
                    onClick={() => {
                      setCategory(null)
                      setShowCategories(false)
                      setShowBestDomains(false)
                      setShowLastDomains(true)
                      setTimeout(() => {
                        executeScroll(lastDomainsRef)
                      }, 1)
                    }}
                    style={{
                      background: 'none',
                      border: 'none',
                      color: '#fff',
                      fontWeight: 300,
                      lineHeight: '3em',
                      fontSize: '1rem',
                      outline: 'none',
                      cursor: 'pointer'
                    }}
                    onMouseEnter={e => {
                      e.target.style.color = 'black'
                      e.target.style.fontWeight = '300'
                    }}
                    onMouseLeave={e => {
                      e.target.style.color = 'white'
                    }}
                  >
                    LAST DOMAINS
                  </button>
                </li>
                <li className='menu-opcion'>
                  <button
                    onClick={() => {
                      setCategory(null)
                      setShowLastDomains(false)
                      setShowBestDomains(false)
                      setShowCategories(true)
                      setTimeout(() => {
                        executeScroll(categoryRef)
                      }, 1)
                    }}
                    style={{
                      background: 'none',
                      border: 'none',
                      color: '#fff',
                      fontWeight: 300,
                      lineHeight: '3em',
                      fontSize: '1rem',
                      outline: 'none',
                      cursor: 'pointer'
                    }}
                    onMouseEnter={e => {
                      e.target.style.color = 'black'
                      e.target.style.fontWeight = '300'
                    }}
                    onMouseLeave={e => {
                      e.target.style.color = 'white'
                    }}
                  >
                    CATEGORIES
                  </button>
                </li>
                <li className='menu-opcion'>
                  <button
                    onClick={() => {
                      setCategory(null)
                      setShowLastDomains(false)
                      setShowCategories(false)
                      setShowBestDomains(true)
                      setTimeout(() => {
                        executeScroll(bestDomainsRef)
                      }, 1)
                    }}
                    style={{
                      background: 'none',
                      border: 'none',
                      color: '#fff',
                      fontWeight: 300,
                      lineHeight: '3em',
                      fontSize: '1rem',
                      outline: 'none',
                      cursor: 'pointer'
                    }}
                    onMouseEnter={e => {
                      e.target.style.color = 'black'
                      e.target.style.fontWeight = '300'
                    }}
                    onMouseLeave={e => {
                      e.target.style.color = 'white'
                    }}
                  >
                    BEST DOMAINS
                  </button>
                </li>
                <li className='menu-opcion'>
                  <button
                    style={{
                      background: 'none',
                      border: 'none',
                      color: '#fff',
                      fontWeight: 300,
                      lineHeight: '3em',
                      fontSize: '1rem',
                      outline: 'none',
                      cursor: 'pointer'
                    }}
                    onMouseEnter={e => {
                      e.target.style.color = 'black'
                      e.target.style.fontWeight = '300'
                    }}
                    onMouseLeave={e => {
                      e.target.style.color = 'white'
                    }}
                    onClick={() => {
                      executeScroll(contactRef)
                    }}
                  >
                    CONTACT
                  </button>
                </li>
                <li className='relative enlinea'>
                  <button
                    className='link-dropdownmenu'
                    style={{
                      background: 'none',
                      border: 'none',
                      outline: 'none',
                      cursor: 'pointer'
                    }}
                    onClick={e => {
                      const languageMenu = document.getElementById('idiomas')
                      if (languageMenu.style.display === 'block') {
                        languageMenu.style.display = 'none'
                      } else {
                        languageMenu.style.display = 'block'
                      }
                    }}
                  >
                    <img
                      className='dropdown-menu-imagen'
                      src={isSpanish ? esFlag : enFlag}
                      alt={isSpanish ? 'spanish flag' : 'english flag'}
                    />
                    <i className='triangulo'></i>
                  </button>
                  <ul className='dropdown-menu' id='idiomas'>
                    <li className='dropdown-menu-idioma'>
                      <button
                        className='dropdown-menu-enlace'
                        style={{
                          background: 'none',
                          border: 'none',
                          outline: 'none',
                          cursor: 'pointer'
                        }}
                        onClick={e => {
                          setIsSpanish(false)
                          const languageMenu = document.getElementById(
                            'idiomas'
                          )
                          languageMenu.style.display = 'none'
                        }}
                      >
                        <img src={enFlag} alt='Idioma' /> English
                      </button>
                    </li>
                    <li className='dropdown-menu-idioma'>
                      <button
                        className='dropdown-menu-enlace activo'
                        style={{
                          background: 'none',
                          border: 'none',
                          outline: 'none',
                          cursor: 'pointer'
                        }}
                        onClick={e => {
                          setIsSpanish(true)
                          const languageMenu = document.getElementById(
                            'idiomas'
                          )
                          languageMenu.style.display = 'none'
                        }}
                      >
                        <img src={esFlag} alt='Idioma' /> Español
                      </button>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>

            <div
              className='icos-menu der'
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start'
              }}
            >
              <div
                className='relative enlinea'
                style={{ marginTop: '-0.5rem' }}
              >
                <button
                  className='link-dropdownmenu'
                  onClick={e => {
                    const mobilelanguageMenu = document.getElementById(
                      'idiomas2'
                    )
                    if (mobilelanguageMenu.style.display === 'block') {
                      mobilelanguageMenu.style.display = 'none'
                    } else {
                      mobilelanguageMenu.style.display = 'block'
                    }
                  }}
                  style={{
                    background: 'none',
                    border: 'none',
                    outline: 'none',
                    cursor: 'pointer'
                  }}
                >
                  <img
                    src={isSpanish ? esFlag : enFlag}
                    alt={isSpanish ? 'spanish flag' : 'english flag'}
                  />
                  <i className='triangulo'></i>
                </button>
                <ul className='dropdown-menu' id='idiomas2'>
                  <li className='dropdown-menu-idioma'>
                    <button
                      className='dropdown-menu-enlace'
                      onClick={e => {
                        setIsSpanish(false)
                        const mobileLanguageMenu = document.getElementById(
                          'idiomas2'
                        )
                        mobileLanguageMenu.style.display = 'none'
                      }}
                      style={{
                        background: 'none',
                        border: 'none',
                        outline: 'none',
                        cursor: 'pointer'
                      }}
                    >
                      <img src={enFlag} alt='Idioma' /> English
                    </button>
                  </li>
                  <li className='dropdown-menu-idioma'>
                    <button
                      className='dropdown-menu-enlace activo'
                      onClick={e => {
                        setIsSpanish(true)
                        const mobileLanguageMenu = document.getElementById(
                          'idiomas2'
                        )
                        mobileLanguageMenu.style.display = 'none'
                      }}
                      style={{
                        background: 'none',
                        border: 'none',
                        outline: 'none',
                        cursor: 'pointer'
                      }}
                    >
                      <img src={esFlag} alt='Idioma' /> Español
                    </button>
                  </li>
                </ul>
              </div>
              <div style={{ marginTop: '-0.1rem' }}>
                <button
                  className='icos-menu__link sepizq'
                  onClick={e => {
                    const mobilemenu = document.getElementById('menumovil')
                    if (mobilemenu.style.display === 'block') {
                      mobilemenu.style.display = 'none'
                      setMobileOpen(false)
                    } else {
                      mobilemenu.style.display = 'block'
                      setMobileOpen(true)
                    }
                  }}
                  style={{
                    background: 'none',
                    outline: 'none',
                    border: 'none',
                    fontSize: '1.5rem',
                    color: '#fff',
                    transition: 'all 500ms ease'
                  }}
                >
                  {mobileOpen ? (
                    <i className='fa fa-times' aria-hidden='true'></i>
                  ) : (
                    <i className='fa fa-bars' aria-hidden='true'></i>
                  )}
                </button>
              </div>
            </div>

            <div id='menumovil'>
              <ul className='menu-movil'>
                <li className='menu-opcionm'>
                  <button
                    style={{
                      border: 'none',
                      outline: 'none',
                      width: '100%',
                      fontSize: '1.2rem',

                      lineHeight: '24px',
                      padding: '20px 20px',
                      color: '#777777'
                    }}
                    onClick={() => {
                      setCategory(null)
                      setShowCategories(false)
                      setShowBestDomains(false)
                      setShowLastDomains(true)
                      setTimeout(() => {
                        executeScroll(lastDomainsRef)
                      }, 1)
                    }}
                  >
                    LAST DOMAINS
                  </button>
                </li>
                <li className='menu-opcionm'>
                  <button
                    style={{
                      border: 'none',
                      outline: 'none',
                      width: '100%',
                      fontSize: '1.2rem',

                      lineHeight: '24px',
                      padding: '20px 20px',
                      color: '#777777'
                    }}
                    onClick={() => {
                      setCategory(null)
                      setShowLastDomains(false)
                      setShowBestDomains(false)
                      setShowCategories(true)
                      setTimeout(() => {
                        executeScroll(categoryRef)
                      }, 1)
                    }}
                  >
                    CATEGORIES
                  </button>
                </li>
                <li className='menu-opcionm'>
                  <button
                    style={{
                      border: 'none',
                      outline: 'none',
                      width: '100%',
                      fontSize: '1.2rem',

                      lineHeight: '24px',
                      padding: '20px 20px',
                      color: '#777777'
                    }}
                    onClick={() => {
                      setCategory(null)
                      setShowLastDomains(false)
                      setShowCategories(false)
                      setShowBestDomains(true)
                      setTimeout(() => {
                        executeScroll(bestDomainsRef)
                      }, 1)
                    }}
                  >
                    BEST DOMAINS
                  </button>
                </li>
                <li className='menu-opcionm'>
                  <button
                    style={{
                      border: 'none',
                      outline: 'none',
                      width: '100%',
                      fontSize: '1.2rem',

                      lineHeight: '24px',
                      padding: '20px 20px',
                      color: '#777777'
                    }}
                    onClick={() => {
                      executeScroll(contactRef)
                    }}
                  >
                    CONTACT
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className='fila'>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end'
            }}
          >
            <form
              className='searchform'
              onSubmit={e => {
                e.preventDefault()
              }}
            >
              <input
                className='searchinput'
                type='search'
                onChange={e => {
                  setSearchParameter(e.target.value)
                  executeScroll(searchRef)
                  if (e.target.value.trim() === '') {
                    setSearchParameter(null)
                    window.scrollTo(0, 0)
                  }
                }}
              />
              <i className='fa fa-search searchfa'></i>
            </form>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Navbar
