import React, { useState } from 'react'

// Formik & Yup
import { Formik } from 'formik'
import * as Yup from 'yup'

// axios
import axios from 'axios'

// SweetAlert
import swal from 'sweetalert'

// RECAPTCHA
import ReCAPTCHA from 'react-google-recaptcha'

const EmailSchema = Yup.object().shape({
  firstName: Yup.string().required('Required *'),
  lastName: Yup.string().required('Required *'),
  email: Yup.string().required('Required *'),
  phoneNumber: Yup.number()
})

const Contact = props => {
  const { contactRef } = props
  const [error, setError] = useState(null)
  const [captcha, setCaptcha] = useState(null)
  const handleSubmit = values => {
    const newMail = {
      mailFrom: 'inquiries@mydomainplace.com',
      mailTo: ['nathan@siouxmedia.com', 'develop@estudiocactus.com'],
      subject: `Domain Inquiry`,
      html: `<div><h1> ${values.firstName}  ${values.lastName} </h1>
        <p> Email: ${values.email} </p>
        <p> Phone: ${values.phoneNumber} </p>
        <p> Message: ${values.message} </p> 
      <div>`
    }

    axios
      .post(
        'https://us-central1-social-cash-out.cloudfunctions.net/sendEmail',
        newMail
      )
      .then(res => console.log('Success!', res))
      .catch(e => console.log(e))
  }
  return (
    <div className='fon-formulario' id='contact' ref={contactRef}>
      <div className='contenedor'>
        <div className='fila-flex'>
          <div className='col12-flex'>
            <div className='full center'>
              <div className='separa1'></div>
              <h2 className='tit-form fuente-destacada c-blanco'>CONTACT US</h2>
              <div className='separa-min'></div>
              <h3 className='subtit-form fuente-destacada c-blanco'>
                Email us with general comments or other inquiries
              </h3>
              <div className='separa'></div>
            </div>
          </div>
          <div className='col03-flex'></div>
          <div className='col06-flex'>
            <Formik
              initialValues={{
                firstName: '',
                lastName: '',
                email: '',
                phoneNumber: '',
                message: 'Message...'
              }}
              validationSchema={EmailSchema}
              enableReinitialize
            >
              {({ values, validateForm, setFieldValue, resetForm }) => (
                <div style={{ marginTop: '1.5rem' }}>
                  <form
                    id='formulario_inferior'
                    name='formulario_inferior'
                    className='form_top'
                    method='post'
                    target='self'
                  >
                    <div className='fila-flex'>
                      <div className='col06-flex'>
                        <input
                          className='formulario obligatorio full'
                          name='nombre'
                          type='text'
                          title='First Name'
                          value={values.firstName}
                          placeholder='First Name'
                          onChange={e => {
                            setError(null)
                            setFieldValue('firstName', e.target.value)
                          }}
                        />
                      </div>
                      <div className='col06-flex'>
                        <input
                          className='formulario obligatorio full'
                          name='apellidos'
                          type='text'
                          title='Last Name'
                          value={values.lastName}
                          placeholder='Last Name'
                          onChange={e => {
                            setError(null)
                            setFieldValue('lastName', e.target.value)
                          }}
                        />
                      </div>
                      <div className='col06-flex'>
                        <input
                          className='formulario obligatorio full'
                          name='email'
                          type='text'
                          title='Email'
                          placeholder='Email'
                          required=''
                          value={values.email}
                          onChange={e => {
                            setError(null)
                            setFieldValue('email', e.target.value)
                          }}
                        />
                      </div>
                      <div className='col06-flex'>
                        <input
                          className='formulario obligatorio full'
                          name='telefono'
                          type='text'
                          title='Phone Number'
                          placeholder='Phone Number'
                          required=''
                          value={values.phoneNumber}
                          onChange={e => {
                            setError(null)
                            setFieldValue('phoneNumber', e.target.value)
                          }}
                        />
                      </div>
                      <div className='col12-flex'>
                        <textarea
                          className='formulario redondo-min notfull'
                          name='mensaje_texto'
                          id='mensaje_texto'
                          rows='6'
                          value={values.message}
                          onChange={e => {
                            setError(null)
                            setFieldValue('message', e.target.value)
                          }}
                        ></textarea>
                      </div>

                      <div className='col12-flex'>
                        <div style={{ marginLeft: '8rem' }}>
                          <ReCAPTCHA
                            sitekey='6Lfrk7QZAAAAACWmEey3YueHZ2FWNVOH8DOLxfYG'
                            onChange={e => {
                              setCaptcha(true)
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className='col12-flex'
                        style={{ color: 'red', textAlign: 'center' }}
                      >
                        {error}
                      </div>
                      <div className='col12-flex'>
                        <div className='center full'>
                          <div className='separa'></div>
                          <input
                            name='enviar'
                            className='formulario boton enviar full fuente-destacada'
                            id='enviar'
                            type='button'
                            value='Send Message'
                            onClick={e => {
                              e.preventDefault()
                              validateForm()
                                .then(res => {
                                  let errors = []
                                  for (const value in res) {
                                    errors.push(value)
                                  }
                                  if (errors.length > 0 && !res.phoneNumber) {
                                    setError(
                                      'Please fill in all the required fields'
                                    )
                                  } else if (res.phoneNumber) {
                                    setError(
                                      'Please provide a valid phone number'
                                    )
                                  } else if (!captcha) {
                                    setError('Please complete the captcha!')
                                  } else {
                                    handleSubmit(values)
                                    resetForm({})
                                    swal(
                                      'Message Sent!',
                                      'Click ok to continue!',
                                      'success'
                                    )
                                  }
                                })
                                .catch(e => console.log(e))
                            }}
                          />
                          <div className='separa1'></div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              )}
            </Formik>
          </div>
          <div className='col03-flex'></div>
        </div>
      </div>
    </div>
  )
}

export default Contact
