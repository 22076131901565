import React from 'react'

import { useHistory } from 'react-router-dom'

const Redirect = props => {
  const { domainData } = props
  const linkTo = `/?domain=${domainData.url}&showLanding=true`
  const history = useHistory()

  return domainData.templateType === 'redirect with banner' ? (
    <div className='wide ajustalalto'>
      <div className='page-wrapper ajustalalto-contenido'>
        <div className='contenidoweb'>
          <iframe
            src={domainData.redirectURL}
            title='Google'
            style={{ width: '100%', height: '100vh' }}
          ></iframe>
          <a className='box-nodomain' href={linkTo}>
            The domain <strong>{domainData.url}</strong> is for sale. To
            purchase, call Afternic.com at +1 781-373-6847 or 855-201-2286.
            Click here for more details.
          </a>
        </div>
      </div>
    </div>
  ) : (
    history.push(domainData.redirectURL)
  )
}

export default Redirect
