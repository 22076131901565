import React from 'react'

const Category = props => {
  const { domains, category, isSpanish } = props
  return (
    <div className='contenedor'>
      <div className='fila-flex'>
        <div className='col12-flex' id='best'>
          <div className='box-list3 fuente-destacada'>
            <span>{category.toUpperCase()} DOMAINS</span>
          </div>
          <table className='list-domains'>
            <tbody>
              <tr>
                <th className='fuente-destacada'>Domain</th>
                <th className='fuente-destacada'>Precio</th>
                <th className='fuente-destacada'></th>
              </tr>
              {domains
                .filter(domain => domain.category === category)
                .map((domain, index) => {
                  const linkTo =
                    `/?domain=${domain.url}&showLanding=true` +
                    (isSpanish ? '&es=true' : '')
                  if (index < 6) {
                    return (
                      <tr>
                        <td className='txt-col1 elipsis c-destacado1'>
                          {domain.url}
                        </td>
                        <td className='txt-col2 c-verde'>{domain.price}$</td>
                        <td className='txt-col3'>
                          <a
                            href={linkTo}
                            className='boton buy fuente-destacada'
                          >
                            BUY NOW
                          </a>
                        </td>
                      </tr>
                    )
                  } else {
                    return null
                  }
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default Category
