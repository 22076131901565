import React from 'react'
import ReactDOM from 'react-dom'
import './fonts/RobotoCondensed-Regular.ttf'
import './styles/body.css'
import './styles/custom.css'
import './styles/flexible.css'
import './styles/formularios.css'
import './styles/textos.css'
import './index.css'

import { createBrowserHistory } from 'history'
import { Router, Route, Switch, Redirect } from 'react-router-dom'

import App from './App'
import * as serviceWorker from './serviceWorker'

export const hist = createBrowserHistory()

ReactDOM.render(
  <React.StrictMode>
    <Router history={hist}>
      <Switch>
        <Route
          path='/'
          render={props => {
            return <App history={hist} {...props} />
          }}
        />
        <Redirect to='/' />
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
)

serviceWorker.unregister()
