import React, { useState, useEffect } from 'react'

// Firebase
import firebase from './firebase/Firebase'

// QueryString
import queryString from 'query-string'

// Components
import Landing from './components/Landing'
import Redirect from './components/Redirect'
import Root from './components/Root'

//Url Parse
import Url from 'url-parse'

//React Helmet
import { Helmet } from 'react-helmet'

//ReactGA
import ReactGA from 'react-ga'
const googleAnalyticsId = 'UA-173266665-1'
ReactGA.initialize(googleAnalyticsId)
ReactGA.pageview(window.document.URL + window.location.search)

const App = () => {
  const db = firebase.firestore()
  const parsedData = queryString.parse(window.location.search)
  const parsedURL = new Url(window.location)
  const domain = parsedData.domain || parsedURL.host
  const redirectToLanding = parsedData.showLanding
  const esLanguage = parsedData.es ? true : false
  const [isSpanish, setIsSpanish] = useState(esLanguage)
  const [showLastDomains, setShowLastDomains] = useState(false)
  const [showCategories, setShowCategories] = useState(false)
  const [showBestDomains, setShowBestDomains] = useState(false)
  const [category, setCategory] = useState(null)
  const [loading, setLoading] = useState(true)
  const [domains, setDomains] = useState([])
  const [domainData, setDomainData] = useState(null)
  const templateType = domainData ? domainData.templateType : null

  const allDomains = domains ? domains.map(domain => domain.url) : []
  useEffect(() => {
    db.collection('domains')
      .get()
      .then(data => {
        let extractedDomains = []
        data.forEach(doc => {
          extractedDomains.push(doc.data())
          if (doc.data().url === domain) {
            setDomainData(doc.data())
          }
        })
        setDomains(extractedDomains)
        setLoading(false)
      })
      .catch(e => {
        console.log(e)
      })
  }, [domain, db])

  return (
    <div className='App'>
      {loading ? (
        <div style={{ marginTop: '10rem' }}>
          <div className='spinner'></div>
          <h6
            style={{
              width: '100%',
              textAlign: 'center',
              fontSize: '1rem',
              marginTop: '-6rem',
              color: '#EAB30F'
            }}
          >
            {isSpanish ? 'Cargando...' : 'Loading...'}
          </h6>
        </div>
      ) : (
        <div>
          {templateType === 'form' ? (
            <div>
              <Helmet>
                <meta charSet='utf-8' />
                <title>{domainData.url} is for sale</title>
              </Helmet>
              <Landing domainData={domainData} isSpanish={isSpanish} />
            </div>
          ) : null}
          {(templateType === 'redirect' ||
            templateType === 'redirect with banner') &&
          !redirectToLanding ? (
            <div>
              <Helmet>
                <meta charSet='utf-8' />
                <title>{domainData.url} is for sale</title>
              </Helmet>
              {console.log('redirecting')}
              <Redirect
                domainData={domainData}
                domains={domains}
                isSpanish={isSpanish}
              />
            </div>
          ) : null}
          {((templateType === 'redirect' && redirectToLanding) ||
            templateType === 'redirect with banner') &&
          redirectToLanding ? (
            <div>
              <Helmet>
                <meta charSet='utf-8' />
                <title>{domainData.url} is for sale</title>
              </Helmet>
              <Landing domainData={domainData} isSpanish={isSpanish} />
            </div>
          ) : null}
          {!templateType ? (
            <div>
              <Helmet>
                <meta charSet='utf-8' />
              </Helmet>
              <Root
                domains={domains}
                showCategories={showCategories}
                setShowCategories={setShowCategories}
                showBestDomains={showBestDomains}
                setShowBestDomains={setShowBestDomains}
                showLastDomains={showLastDomains}
                setShowLastDomains={setShowLastDomains}
                category={category}
                setCategory={setCategory}
                isSpanish={isSpanish}
                setIsSpanish={setIsSpanish}
              />
            </div>
          ) : null}
        </div>
      )}
    </div>
  )
}

export default App
