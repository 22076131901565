import React, { useEffect } from 'react'

const Search = props => {
  const {
    domains,
    searchParameter,
    isSpanish,
    categories,
    setShowCategories,
    setCategory,
    searchRef,
    singleCategoryRef,
    executeScroll
  } = props
  const capitalizeFirstLetter = string => {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  useEffect(() => {}, [])
  return (
    <div className='contenedor' style={{ marginBottom: '10rem' }}>
      <div className='fila-flex'>
        <div className='col12-flex' id='last' ref={searchRef}>
          <div className='box-list1 fuente-destacada'>
            <span>Domains including {searchParameter} : </span>
          </div>
          {domains.filter(domain =>
            domain.url.includes(searchParameter.toLowerCase())
          ).length > 0 ? (
            <table className='list-domains'>
              <tbody>
                <tr>
                  <th className='fuente-destacada'>Domain</th>
                  <th className='fuente-destacada'>Price</th>
                  <th className='fuente-destacada'></th>
                </tr>

                {domains
                  ? domains
                      .filter(doc =>
                        doc.url.includes(searchParameter.toLowerCase())
                      )
                      .sort((a, b) => Number(a.createdAt) - Number(b.createdAt))
                      .map((domain, index) => {
                        const linkTo =
                          `/?domain=${domain.url}&showLanding=true` +
                          (isSpanish ? '&es=true' : '')

                        return (
                          <tr key={index}>
                            <td
                              className='txt-col1 elipsis c-destacado1'
                              title={domain.url}
                            >
                              {domain.url}
                            </td>
                            <td className='txt-col2 c-verde'>
                              {domain.price}$
                            </td>
                            <td className='txt-col3'>
                              <a
                                href={linkTo}
                                className='boton buy fuente-destacada'
                              >
                                BUY NOW
                              </a>
                            </td>
                          </tr>
                        )
                      })
                  : null}
              </tbody>
            </table>
          ) : (
            <div>
              <h5
                style={{
                  textAlign: 'center',
                  marginTop: '1rem',
                  fontWeight: '300'
                }}
              >
                {' '}
                No domains include your search criteria{' '}
              </h5>
            </div>
          )}
        </div>
      </div>
      <div className='fila-flex'>
        <div className='col12-flex' id='categories'>
          <div className='box-list2 fuente-destacada'>
            <span>Categories including {searchParameter} : </span>
          </div>
          {categories.filter(category => category.includes(searchParameter))
            .length > 0 ? (
            <div className='box-categories'>
              <div className='fila-flex'>
                {categories
                  .filter(category => category.includes(searchParameter))
                  .map((category, index) => {
                    const catNameArr = category.split(' ')
                    const newCatNameArr = catNameArr.map(cat =>
                      capitalizeFirstLetter(cat)
                    )
                    const categoryName = newCatNameArr.join(' ')
                    return (
                      <div
                        key={index}
                        className='col06-flex col06-flex-xs col04-flex-xl  col03-flex-xxl'
                      >
                        <button
                          className='box-categories-item elipsis'
                          onClick={() => {
                            setShowCategories(false)
                            setCategory(category)
                            executeScroll(singleCategoryRef)
                          }}
                          style={{
                            border: 'none',
                            outline: 'none',
                            width: '100%',
                            fontSize: '1rem',
                            textAlign: 'left'
                          }}
                        >
                          <span className='cajita sepder'></span>
                          {categoryName}
                        </button>
                      </div>
                    )
                  })}
              </div>
            </div>
          ) : (
            <div>
              <h5
                style={{
                  textAlign: 'center',
                  marginTop: '1rem',
                  fontWeight: '300'
                }}
              >
                {' '}
                No categories include your search criteria{' '}
              </h5>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Search
