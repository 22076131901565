import fb from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/functions'
const config = {
  apiKey: 'AIzaSyBbhzLFs4vBdoakoMp4uaET-7z0WMNtlmA',
  authDomain: 'my-domain-place.firebaseapp.com',
  databaseURL: 'https://my-domain-place.firebaseio.com',
  projectId: 'my-domain-place',
  storageBucket: 'my-domain-place.appspot.com',
  messagingSenderId: '54429690219',
  appId: '1:54429690219:web:efe052d9b28b999e31842e'
}
const firebase = fb.initializeApp(config)
export default firebase
