import React from 'react'

const Domains = props => {
  const {
    domains,
    categories,
    bestDomains,
    setCategory,
    setShowBestDomains,
    setShowCategories,
    setShowLastDomains,
    domainsRef,
    isSpanish
  } = props

  const capitalizeFirstLetter = string => {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  return (
    <div className='contenedor'>
      <div className='fila-flex'>
        <div className='col04-flex' id='last' ref={domainsRef}>
          <div className='box-list1 fuente-destacada'>
            <span>LAST DOMAINS ADDED</span>
          </div>
          <table className='list-domains'>
            <tbody>
              <tr>
                <th className='fuente-destacada'>Domain</th>
                <th className='fuente-destacada'>Price</th>
                <th className='fuente-destacada'></th>
              </tr>
              {domains
                ? domains
                    .sort((a, b) => Number(b.createdAt) - Number(a.createdAt))
                    .map((domain, index) => {
                      const linkTo =
                        `/?domain=${domain.url}&showLanding=true` +
                        (isSpanish ? `&es=true` : '')
                      if (index < 6) {
                        return (
                          <tr key={index}>
                            <td
                              className='txt-col1 elipsis c-destacado1'
                              title={domain.url}
                            >
                              {domain.url}
                            </td>
                            <td className='txt-col2 c-verde'>
                              {domain.price}$
                            </td>
                            <td className='txt-col3'>
                              <a
                                href={linkTo}
                                className='boton buy fuente-destacada'
                              >
                                BUY NOW
                              </a>
                            </td>
                          </tr>
                        )
                      } else {
                        return null
                      }
                    })
                : null}
            </tbody>
          </table>
          <div className='separa1'></div>
          <div className='full center'>
            <button
              className='boton more'
              onClick={() => {
                setShowBestDomains(false)
                setShowCategories(false)
                setShowLastDomains(true)
              }}
              style={{ outline: 'none' }}
            >
              See More
            </button>
          </div>
          <div className='separa1'></div>
        </div>
        <div className='col04-flex' id='categories'>
          <div className='box-list2 fuente-destacada'>
            <span>SORT BY CATEGORY</span>
          </div>
          <div className='box-list2frs'>
            <span className='fuente-destacada'>
              Browse popular categories here
            </span>
          </div>
          <div className='box-categories'>
            <div className='fila-flex'>
              {categories
                .sort((a, b) => {
                  if (a < b) {
                    return -1
                  }
                  if (a > b) {
                    return 1
                  }
                  return 0
                })
                .map((category, index) => {
                  const catNameArr = category.split(' ')
                  const newCatNameArr = catNameArr.map(cat =>
                    capitalizeFirstLetter(cat)
                  )
                  const categoryName = newCatNameArr.join(' ')

                  if (index < 14) {
                    return (
                      <div className='col06-flex col06-flex-xs' key={index}>
                        <button
                          className='box-categories-item elipsis'
                          onClick={() => {
                            setShowCategories(false)
                            setCategory(category)
                          }}
                          style={{
                            border: 'none',
                            outline: 'none',
                            width: '100%',
                            fontSize: '1rem',
                            textAlign: 'left'
                          }}
                        >
                          <span className='cajita sepder'></span>
                          {categoryName}
                        </button>
                      </div>
                    )
                  } else {
                    return null
                  }
                })}
            </div>
          </div>
          <div className='separa1'></div>
          <div className='full center' style={{ marginTop: '1.7rem' }}>
            <button
              className='boton more'
              onClick={() => {
                setShowBestDomains(false)
                setShowLastDomains(false)
                setShowCategories(true)
              }}
            >
              See More
            </button>
          </div>
          <div className='separa1'></div>
        </div>
        <div className='col04-flex' id='best'>
          <div className='box-list3 fuente-destacada'>
            <span>BEST DOMAINS</span>
          </div>
          <table className='list-domains'>
            <tbody>
              <tr>
                <th className='fuente-destacada'>Domain</th>
                <th className='fuente-destacada'>Price</th>
                <th className='fuente-destacada'></th>
              </tr>
              {bestDomains
                .sort((a, b) => b.price - a.price)
                .map((domain, index) => {
                  const linkTo =
                    `/?domain=${domain.url}&showLanding=true` +
                    (isSpanish ? `&es=true` : '')
                  if (index < 6) {
                    return (
                      <tr key={index}>
                        <td className='txt-col1 elipsis c-destacado1'>
                          {domain.url}
                        </td>
                        <td className='txt-col2 c-verde'>{domain.price}$</td>
                        <td className='txt-col3'>
                          <a
                            href={linkTo}
                            className='boton buy fuente-destacada'
                          >
                            BUY NOW
                          </a>
                        </td>
                      </tr>
                    )
                  } else {
                    return null
                  }
                })}
            </tbody>
          </table>
          <div className='separa1'></div>
          <div className='full center'>
            <button
              className='boton more'
              onClick={() => {
                setShowLastDomains(false)
                setShowCategories(false)
                setShowBestDomains(true)
              }}
            >
              See More
            </button>
          </div>
          <div className='separa1'></div>
        </div>
      </div>
    </div>
  )
}

export default Domains
