import React, { useState } from 'react'

const BestDomains = props => {
  const { bestDomains, bestDomainsRef, isSpanish } = props
  const [limit, setLimit] = useState(19)
  return (
    <div className='contenedor'>
      <div className='fila-flex'>
        <div className='col12-flex' id='best' ref={bestDomainsRef}>
          <div className='box-list3 fuente-destacada'>
            <span>BEST DOMAINS</span>
          </div>
          <table className='list-domains'>
            <tbody>
              <tr>
                <th className='fuente-destacada'>Domain</th>
                <th className='fuente-destacada'>Price</th>
                <th className='fuente-destacada'></th>
              </tr>
              {bestDomains
                .sort((a, b) => b.price - a.price)
                .map((domain, index) => {
                  const linkTo =
                    `/?domain=${domain.url}&showLanding=true` +
                    (isSpanish ? '&es=true' : '')
                  if (index <= limit) {
                    return (
                      <tr key={index}>
                        <td className='txt-col1 elipsis c-destacado1'>
                          {domain.url}
                        </td>
                        <td className='txt-col2 c-verde'>{domain.price}$</td>
                        <td className='txt-col3'>
                          <a
                            href={linkTo}
                            className='boton buy fuente-destacada'
                          >
                            BUY NOW
                          </a>
                        </td>
                      </tr>
                    )
                  } else {
                    return null
                  }
                })}
            </tbody>
          </table>
        </div>
        <div class='separa1'></div>
        <div class='full center'>
          <button
            className='boton more'
            onClick={e => {
              setLimit(Number(limit) + 20)
            }}
          >
            See More
          </button>
        </div>
      </div>
    </div>
  )
}
export default BestDomains
